import i18n from '@/locale';
import moment from 'moment';
import chartOptions from '../chart/chart_options';

const heatmapWeeklyHour = (res, measureSelect, myChart) => {
  const yAxisData = [];
  const xAxisData = [];

  for (let i = 5; i < 22; i++) {
    let h;
    i < 10 ? (h = `0${i}:00`) : (h = `${i}:00`);
    xAxisData.push(h);
  }

  const cur_Date = moment().format('YYYY-MM-DD');
  const yAxisDatas_label = [];
  const yAxisDatas = [];

  for (let i = 6; i >= 0; i--) {
    const tmpd = moment(cur_Date).add(-i, 'day').format('ddd');
    const d = moment(cur_Date).add(-i, 'day').format('YYYY-MM-DD');
    yAxisDatas.push(tmpd);
    const day = parseInt(moment(cur_Date).add(-i, 'day').format('DD'));
    const month = moment(cur_Date).add(-i, 'day').format('MMM');
    yAxisDatas_label.push(`${i18n.t(`date.${tmpd}`)} ${day} ${i18n.t(`date.${month}`)}`);
  }

  const hourData = [];
  let max = 0;

  const result = {};
  Object.keys(res.data.data).forEach((key) => {
    const time = res.data.data[key].Time;
    const tmpd = moment(time).format('ddd');

    if (yAxisData.indexOf(tmpd) < -1) {
      yAxisData.push(tmpd);
    }
    if (!result[time]) {
      result[time] = [];
    }
    result[time].push(res.data.data[key]);
  });

  Object.keys(result).forEach((key) => {
    const sortType = 'Hour';
    const res = result[key].sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });

    res.forEach((item) => {
      let tmpData = [];
      const tmpd = moment(item.Time).format('ddd');
      const dayIndex = yAxisDatas.indexOf(tmpd);

      // eslint-disable-next-line radix
      const measureVal = parseInt(item[measureSelect.value]);

      if (max < measureVal) {
        max = measureVal;
      }

      // eslint-disable-next-line radix
      const hour = parseInt(item.Hour);
      tmpData = [dayIndex, hour - 5, measureVal];
      hourData.push(tmpData);
    });
  });
  
  const data = hourData.map((item) => [item[1], item[0], item[2] || '-']);

  console.log(max)
  const min = 0;
  const series = [{
    name: `${measureSelect.text} (${measureSelect.unit})`,
    type: 'heatmap',
    data,
    label: {
      show: false,
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  }];

  const grid = {
    height: '85%',
    top: '5%',
    width: '84%',

  };
  const tooltip = {
    position: 'top',
  };

  chartOptions.echartHeatmapOptions(
    series,
    xAxisData,
    yAxisDatas_label,
    min,
    max,
    grid,
    tooltip,
    myChart,
  );
};

const heatmapHour = (res, measureSelect, santralModel, step) => new Promise((resolve) => {
  const xAxisData = [];

  for (let i = 5; i < 21; i++) {
    for (let x = 0; x < 60; x += step) {
      let h;
      let
        m;
      i < 10 ? h = `0${i}` : h = `${i}`;
      x < 10 ? m = `0${x}` : m = `${x}`;
      const time = `${h}:${m}`;

      xAxisData.push(time);
    }
  }

  const yAxisDatas_label = santralModel;
  let hourData = [];
  let max = 0;

  if (res.success == 'true') {
    Object.keys(res.data).forEach((key) => {
      let tmpData = [];
      const measureVal = parseInt(res.data[key][measureSelect.value]);

      if (max < measureVal) {
        max = measureVal;
      }

      const hour = parseInt(res.data[key].Hour);
      const minute = parseInt(res.data[key].MinuteGrup);
      let h; let
        m;

      hour < 10 ? h = `0${hour}` : h = `${hour}`;
      minute < 10 ? m = `0${minute}` : m = `${minute}`;
      const time = `${h}:${m}`;

      tmpData = [res.data[key].santral, time, measureVal];
      hourData.push(tmpData);
    });
  }

  hourData = hourData.map((item) => [item[1], item[0], item[2] || '-']);
  const min = 0;
  const series = [{
    name: `${measureSelect.text} (${measureSelect.unit})`,
    type: 'heatmap',
    data: hourData,
    label: {
      show: false,
    },
    emphasis: {
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
  }];

  const grid = {
    height: '75%',
    top: '5%',
    width: '84%',
  };
  const tooltip = {
    position: 'top',
  };

  const obj = chartOptions.echartHeatmap_Options(
    series,
    xAxisData,
    yAxisDatas_label,
    min,
    max,
    grid,
    tooltip,
  );

  resolve(obj);
});

export default {
  heatmapWeeklyHour,
  heatmapHour,

};
